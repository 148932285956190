/* Global CSS goes here */
body {
  height: 100%;
}

#root {
  height: 100%;
}

// General
// ---
@text-color: white;
@text-color-secondary: white;
@body-background: #30413c;
@layout-header-background: #202020;
@layout-body-background: #30413c;
@component-background: #30413c;
@font-family: "Poppins", sans serif;

// Menu
// ---
@menu-highlight-color: #fcb040;
